<template>
    <div id="SourceOfApple">
        <CSBreadcrumb/>
        <div class="result-panel">
            <CSTable>
                <template v-slot:thead>
                    <tr>
                        <th>领取时间</th>
                        <th>苹果数量</th>
                        <th>姓名</th>
                        <th>手机号</th>
                        <th>所属企业</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr
                            v-for="redPacketDetailsData in redPacketDetailsDataList"
                            :key="redPacketDetailsData.id"
                    >
                        <td class="date-td">{{redPacketDetailsData.grabTime}}</td>
                        <td>{{redPacketDetailsData.amount}}</td>
                        <td>{{redPacketDetailsData.userName?redPacketDetailsData.userName:'-'}}</td>
                        <td>{{redPacketDetailsData.userPhone}}</td>
                        <td>{{redPacketDetailsData.userCompany?redPacketDetailsData.userCompany:'-'}}</td>
                    </tr>
                </template>
            </CSTable>
            <Pagination></Pagination>
        </div>
    </div>
</template>

<script>
    import CSBreadcrumb from "@/components/common/CSBreadcrumb";
    import {
        querySocialServiceAppleRedPacketDetailsDataUrl
    } from "@/requestUrl";
    import Pagination from "@/components/Pagination.vue";
    import CSTable from "@/components/common/CSTable";

    export default {
        name: "SourceOfApple",
        props: {
            id: Number
        },
        components: {
            CSTable,
            CSBreadcrumb,
            Pagination
        },
        data() {
            return {
                redPacketDetailsDataList: [],
                redPackId: null
            }
        },
        mounted() {
            this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
                this.getRedPacketDetailsData(_currentPage)
            });
            this.getRedPacketDetailsData();
        },
        created() {
            this.redPackId = this.$route.query.redPackId
        },
        methods: {
            // 获取苹果红包数据
            async getRedPacketDetailsData(pageNo = 1,pageSize = 10) {
                let res = await this.$fly.get(
                    querySocialServiceAppleRedPacketDetailsDataUrl, {redPackId: this.redPackId,pageNo}
                )
                if (res.code != 0) {
                    return;
                }
                if (pageNo === 1) {
                    this.$vc.emit(this.$route.path, "pagination", "init", {
                        total: res.data.total,
                        pageSize: pageSize,
                        pageNo: pageNo,
                        // currentPage: pageNo,
                    })

                }
                this.redPacketDetailsDataList = res.data.datas || [];
            },
        },
    }
</script>

<style lang="stylus" scoped>

</style>
